import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
import { Component } from "react";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
import Retweet from "./retweet";



class TopRetweets extends Component {

    render() {
        return <div width={345} className='twitter-feed' >
            {/* <CardContent >
                <Typography gutterBottom variant="h5" component="h2" align="center">
                    Disaster Related Tweets
                </Typography>
                <hr width={'100%'} />
                <Typography variant="h5" color="textSecondary" component="h2" align="center">
                    {this.state.relevant.count+ '/' + this.state.total.count}
                </Typography>
                <Typography variant="h5" color="textSecondary" component="h2" align="center">
                    {(this.state.relevant.count * 100 / this.state.total.count).toFixed(1).toString() + "%"}
                </Typography>
            </CardContent> */}
            <div >
                                    {this.state.retweets.map(post => (
                                        <Retweet
                                            key={post.text}
                                            count={post.count}
                                            url={post.url}
                                            
                                            text={post.text}
                                            
                                        />
                                    ))}
                                </div>
        </div>

    }
    state = {
        retweets: [],
        relevant: []
    };
    componentDidMount() {
        var retweeturl = process.env.REACT_APP_API_URL + '/api/retweets/?limit=10'
        fetch(retweeturl)
            .then(res => res.json())
            .then((data) => {
                this.setState({ retweets: data })
            })
            .catch(console.log)
        var relevanturl = process.env.REACT_APP_API_URL + '/api/count/?relevant=True'
        fetch(relevanturl)
            .then(res => res.json())
            .then((data) => {
                this.setState({ relevant: data })
            })
            .catch(console.log)
    }
}

export default TopRetweets

