import React, { forwardRef } from 'react'
import './retweet.css'
import Typography from "@material-ui/core/Typography";


const Retweet = forwardRef(
    ({
        count,
        text,
        url,

    }, ref) => {
        return (
            <div className="post" ref={ref}>
                <div className="post__avatar">

                    <Typography gutterBottom variant="h5" component="h2" align="center">
                        {count}
                    </Typography>
                </div>
                <div className="post__body">
                    <div className="post__header">

                        <div className="post__headerDescription">
                            <a href={url} target="_blank" rel="noreferrer">
                                {text}
                            </a>

                        </div>
                    </div>

                </div>
            </div>
        )
    })

export default Retweet