import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import "./landingPage.css"
import { Grid } from '@material-ui/core';


class LandingPage extends Component {

    render() {
        return (
            <div>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '50vh' }}>
                    <Grid item xs={5} container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '50vh' }}>
                        <Typography component="h1" variant="h5" align="center" style={{ 'padding': '30px' }}>
                            Welcome to the Landslide Detector
                        </Typography>
                        <Grid item md={8} container
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center">
                            <Grid item>
                                <Button variant="contained" color="primary" href="/map" align="center" style={{ maxWidth: '100px', maxHeight: '35px', minWidth: '100px', minHeight: '35px' }}>
                                    Map
                                </Button>
                            </Grid>
                            {/* <Grid item>
                                <Button variant="contained" color="primary" href="/analytics" align="center" style={{ maxWidth: '100px', maxHeight: '35px', minWidth: '100px', minHeight: '35px' }}>
                                    Analytics
                                </Button>
                            </Grid> */}
                            <Grid item>
                                <Button variant="contained" color="primary" href="/about" align="center" style={{ maxWidth: '100px', maxHeight: '35px', minWidth: '100px', minHeight: '35px' }}>
                                    About
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" href="https://github.com/tbusath8/landslide-detection-aws" target="_blank" rel="noreferrer" align="center" style={{ maxWidth: '100px', maxHeight: '35px', minWidth: '100px', minHeight: '35px' }}>
                                    Github
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <svg width="100%" height="75%" viewBox="0 0 1440 490" fill="none" xmlns="http://www.w3.org/2000/svg" className='footer' >
                    <g clipPath="url(#clip0)">
                        <path d="M0 49L80 114.3C160 180 320 310 480 359.3C640 408 800 376 960 318.5C1120 261 1280 180 1440 171.5C1600 163 1760 229 1920 220.5C2080 212 2240 131 2400 106.2C2560 82 2720 114 2880 155.2C3040 196 3200 245 3360 220.5C3520 196 3680 98 3840 65.3C4000 33 4160 65 4320 98C4480 131 4640 163 4800 171.5C4960 180 5120 163 5280 130.7C5440 98 5600 49 5760 32.7C5920 16 6080 33 6240 89.8C6400 147 6560 245 6720 285.8C6880 327 7040 310 7200 318.5C7360 327 7520 359 7680 367.5C7840 376 8000 359 8160 343C8320 327 8480 310 8640 310.3C8800 310 8960 327 9120 302.2C9280 278 9440 212 9600 187.8C9760 163 9920 180 10080 155.2C10240 131 10400 65 10560 65.3C10720 65 10880 131 11040 147C11200 163 11360 131 11440 114.3L11520 98V490H11440C11360 490 11200 490 11040 490C10880 490 10720 490 10560 490C10400 490 10240 490 10080 490C9920 490 9760 490 9600 490C9440 490 9280 490 9120 490C8960 490 8800 490 8640 490C8480 490 8320 490 8160 490C8000 490 7840 490 7680 490C7520 490 7360 490 7200 490C7040 490 6880 490 6720 490C6560 490 6400 490 6240 490C6080 490 5920 490 5760 490C5600 490 5440 490 5280 490C5120 490 4960 490 4800 490C4640 490 4480 490 4320 490C4160 490 4000 490 3840 490C3680 490 3520 490 3360 490C3200 490 3040 490 2880 490C2720 490 2560 490 2400 490C2240 490 2080 490 1920 490C1760 490 1600 490 1440 490C1280 490 1120 490 960 490C800 490 640 490 480 490C320 490 160 490 80 490H0V49Z" fill="url(#paint0_linear)" />
                        <path opacity="0.9" d="M0 343L80 310.3C160 278 320 212 480 171.5C640 131 800 114 960 114.3C1120 114 1280 131 1440 187.8C1600 245 1760 343 1920 359.3C2080 376 2240 310 2400 277.7C2560 245 2720 245 2880 220.5C3040 196 3200 147 3360 114.3C3520 82 3680 65 3840 106.2C4000 147 4160 245 4320 285.8C4480 327 4640 310 4800 318.5C4960 327 5120 359 5280 310.3C5440 261 5600 131 5760 130.7C5920 131 6080 261 6240 261.3C6400 261 6560 131 6720 65.3C6880 0 7040 0 7200 65.3C7360 131 7520 261 7680 302.2C7840 343 8000 294 8160 269.5C8320 245 8480 245 8640 261.3C8800 278 8960 310 9120 269.5C9280 229 9440 114 9600 81.7C9760 49 9920 98 10080 163.3C10240 229 10400 310 10560 310.3C10720 310 10880 229 11040 179.7C11200 131 11360 114 11440 106.2L11520 98V490H11440C11360 490 11200 490 11040 490C10880 490 10720 490 10560 490C10400 490 10240 490 10080 490C9920 490 9760 490 9600 490C9440 490 9280 490 9120 490C8960 490 8800 490 8640 490C8480 490 8320 490 8160 490C8000 490 7840 490 7680 490C7520 490 7360 490 7200 490C7040 490 6880 490 6720 490C6560 490 6400 490 6240 490C6080 490 5920 490 5760 490C5600 490 5440 490 5280 490C5120 490 4960 490 4800 490C4640 490 4480 490 4320 490C4160 490 4000 490 3840 490C3680 490 3520 490 3360 490C3200 490 3040 490 2880 490C2720 490 2560 490 2400 490C2240 490 2080 490 1920 490C1760 490 1600 490 1440 490C1280 490 1120 490 960 490C800 490 640 490 480 490C320 490 160 490 80 490H0V343Z" fill="url(#paint1_linear)" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear" x1="0" y1="490" x2="0" y2="26.5831" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ef5350" />
                            <stop offset="1" stopColor="#ef9a9a" />
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="0" y1="490" x2="0" y2="16.325" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#ef9a9a" />
                            <stop offset="1" stopColor="#e57373" />
                        </linearGradient>
                        <clipPath id="clip0">
                            <rect width="1440" height="490" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

        )
    }
}

export default LandingPage