import React from 'react';
import GoogleMapReact from 'google-map-react';
import Popover from '@mui/material/Popover';
import Post from './post';
// import { TwitterTweetEmbed } from 'react-twitter-embed';
import "./map.css";

// const markerStyle = {
//   position: "absolute",
//   top: "100%",
//   left: "50%",
//   transform: "translate(-50%, -100%)"
// };

// const AnyReactComponent = ($hover) => (
//     <div >
//       <img style={markerStyle} src={$hover ? "/pin.png" : "/pointer.png"} alt='pin'></img>
//     </div>
//   );

const defaultProps = {

  center: {
    lat: 37.0902,
    lng: -95.7129
  },
  draggableCursor: 'crosshair',
  zoom: 4,
  options: {
    draggableCursor: 'default',
    gestureHandling: 'greedy',
    fullscreenControl: false,
  }
}

const K_MARGIN_TOP = 0;
const K_MARGIN_RIGHT = 0;
const K_MARGIN_BOTTOM = 0;
const K_MARGIN_LEFT = 0;

const K_HOVER_DISTANCE = 6;

const MyMarker = ({ count, tweets, $hover }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log(`You clicked on ${tweets[0].tweet_id}`)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // const handleClick = () => {
  //   console.log(`You clicked on ${tooltip}`)
  // };

  tweets.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
  
  return (
    <div style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
      <div className={$hover ? "circle hover" : "circle"} onClick={handleClick} style={{ "cursor": "pointer" }}>
        {count > 1 ? count : ''}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '400px' },
        }}
      >
        {tweets.filter(x => !!x).map((tweet) =>
          <Post id="postPopup" key={tweet.tweet_id}
            displayName={tweet.user}
            username={tweet.user}
            url={tweet.url}
            timestamp={tweet.timestamp}
            text={tweet.text}
            verified={tweet.verified}
            image={''}
            avatar={tweet.profile_image_url_https}
          />
        )}
        {/* <TwitterTweetEmbed tweetId={tweet_id}/> */}

      </Popover>
    </div>
  );
};


const TweetsMap = ({ tweets }) => {



  return (
    <div style={{ height: '49vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCwwtOtfahHHQv6rxAFLXo9E3mqrPzz_vw" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={defaultProps.options}
        // hoverDistance={50 / 2}
        margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
        hoverDistance={K_HOVER_DISTANCE}
      >
        {tweets.filter(x => !!x).map((mapPoint) =>
          <MyMarker
            key={mapPoint.latitude}
            lat={mapPoint.latitude}
            lng={mapPoint.longitude}
            count={mapPoint.count}
            tweets={mapPoint.tweets}
          // count={mapPoint.count}
          // tooltip={tweet.tweet_id.toString()}
          // tweet_id={tweet.tweet_id.toString()}
          // displayName={tweet.user}
          // username={tweet.user}
          // url={tweet.url}
          // timestamp={tweet.timestamp}
          // verified={tweet.verified}
          // image={''}
          // avatar={tweet.profile_image_url_https}
          // options={{draggableCursor: 'auto'}}

          />

        )}
      </GoogleMapReact>
    </div>
  )

}
export default TweetsMap;