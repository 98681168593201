import React, { Component } from 'react';
// import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
// import "./landingPage.css"
import { Grid } from '@material-ui/core';


class AboutPage extends Component {

    render() {
        return (
            <div>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '50vh' }}>
                    <Grid item xs={5} container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '50vh' }}>
                        <Typography component="h1" variant="h5" align="center" style={{ 'padding': '30px' }}>
                            About Page
                        </Typography>
                    </Grid>
                </Grid>
                
            </div>

        )
    }
}

export default AboutPage