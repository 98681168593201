import { Avatar } from '@material-ui/core'
// import  PublishIcon  from '@material-ui/icons/Publish'
// import  ChatBubbleOutlineIcon  from '@material-ui/icons/ChatBubbleOutline'
// import RepeatIcon from '@material-ui/icons/Repeat'
// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

import React, { forwardRef } from 'react'
import './post.css'

function subtractTime(timestamp) {

    var date = new Date(timestamp)
    var time = date.getTime()

    var now = new Date().getTime() + (new Date().getTimezoneOffset() * 1000 * 60)
    var difference = now - time
    var hours = Math.floor(difference / (1000 * 60 * 60))
    if (hours < 1) {
        return Math.floor(difference / (1000 * 60)).toString() + 'm'
    } else if (hours < 24) {
        return hours.toString() + 'h'
    } else if (date.getFullYear() === new Date().getFullYear()) {
        const month = date.toLocaleString('default', { month: 'short' })
        return month + ' ' + date.getDate()
    } else {
        const month = date.toLocaleString('default', { month: 'short' })
        return month + ' ' + date.getDate + ', ' + date.getFullYear()
    }
    // return 
}

const Post = forwardRef(
    ({
        displayName,
        username,
        verified,
        timestamp,
        text,
        url,
        image,
        avatar
    }, ref) => {
        return (
            <div className="post" ref={ref}>
                <div className="post__avatar">
                    <Avatar component='a' href={'https://twitter.com/' + username} target="_blank" rel="noreferrer"
                        src={avatar}
                    />
                </div>
                <div className="post__body">
                    <div className="post__header">
                        <div className="post__headerText">
                            <h3>
                                <a href={'https://twitter.com/' + username} target="_blank" rel="noreferrer">
                                    {' ' + displayName + ' '}
                                </a>
                                <span className="post__headerSpecial">
                                    {verified && <VerifiedUserIcon className="post__badge" />}
                                    <a href={'https://twitter.com/' + username} target="_blank" rel="noreferrer">
                                        @{' ' + username + ' '}
                                    </a>
                                    {subtractTime(timestamp)}
                                </span>
                            </h3>
                        </div>
                        <div className="post__headerDescription">
                            <a href={url} target="_blank" rel="noreferrer">
                                {text}
                            </a>
                            {/* <p>{text}</p> */}
                        </div>
                    </div>
                    {/* <img 
                    src = {image}
                    alt = ""
                /> */}
                    {/* <div className = "post__footer">
                    <ChatBubbleOutlineIcon fontSize = "small" /> 
                    <RepeatIcon fontSize = "small" />
                    <FavoriteBorderIcon fontSize = "small" />
                    <PublishIcon  fontSize = "small" /> 
                </div> */}
                </div>
            </div>
        )
    })

export default Post