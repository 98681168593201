import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, Label, ResponsiveContainer } from 'recharts';

export default class TimeSeriesChart extends PureComponent {


    render() {
        var options = { year: '2-digit', month: 'numeric', day: 'numeric' }
        var new_array = this.props.data.map(function (row) {
            row.label = new Date(row.date).toLocaleDateString("en-US", options)
            row.date = new Date(row.date).getTime() / 1000;

            return row;
        });
        return (
            <ResponsiveContainer width='100%'
                height='100%'>
                <AreaChart

                    data={new_array}
                    margin={{
                        top: 5,
                        right: 40,
                        left: 10,
                        bottom: 40,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ff7961" stopOpacity={1} />
                            <stop offset="95%" stopColor="#ff7961" stopOpacity={0.4} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="label" angle={45} textAnchor="start" />
                    <YAxis >
                        <Label angle={270} position='left' style={{ textAnchor: 'middle' }}>
                            Count
                        </Label>
                    </YAxis>
                    <Tooltip />
                    <Area type="monotone" dataKey="count" stroke="#ba000d" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>


        );
    }
}
