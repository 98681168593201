import React, { Component } from 'react';
import TweetsMap from './map';
import { Grid } from '@material-ui/core';

// import TotalTweetsCard from './totalTweetsCard'
// import PercentRelevantCard from './percentRelevantCard'
import Post from './post';
import MyLoader from './tweetLoader';
import TimeSeriesChart from './lineGraph';
import MyBarChart from './barChart';


class MapPage extends Component {
    render() {
        var dataIsLoaded = (this.state.contacts.length !== 0)
        var lineDataIsLoaded = (this.state.dates.length !== 0)
        // console.log(dataIsLoaded)
        return (
            <div className="page">
                <Grid container justifyContent="center">
                    <Grid item container spacing={3} justifyContent="center" xs={12}>
                        <Grid item container direction="column" xs={12} sm={7} spacing={3} >
                            <Grid item>
                                <div className="map">
                                    <TweetsMap tweets={this.state.mapPoints}></TweetsMap>
                                </div>
                            </Grid>
                            <Grid item container spacing={1} justifyContent="center">
                                <Grid item xs={12} sm={4}>
                                    {/* <PercentRelevantCard /> */}
                                    <div className='card2'>
                                    <h6>Most Influential Words</h6>
                                        <MyBarChart></MyBarChart>
                                        
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    {/* <TotalTweetsCard></TotalTweetsCard> */}
                                    <div className='card2'>
                                        
                                    {lineDataIsLoaded ?<div className="card2">
                                        <h6>Relevant Tweet Frequency</h6>
                                        <TimeSeriesChart data={this.state.dates}></TimeSeriesChart>
                                        </div>
                                        : <div/>}
                                    </div>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <div className="twitter-feed">
                                {dataIsLoaded ? <div >
                                    {this.state.contacts.map(post => (
                                        <Post
                                            key={post.tweet_id}
                                            displayName={post.user_name}
                                            username={post.user}
                                            url={post.url}
                                            timestamp={post.timestamp}
                                            text={post.text}
                                            verified={post.verified}
                                            image={''}
                                            avatar={post.profile_image_url_https}
                                        />
                                    ))}
                                </div> : <MyLoader style={{ width: '100%', height: '90vh' }} />}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
    state = {
        contacts: [],
        total: [],
        mapPoints: [],
        relevant: [],
        dates:[]
    };

    componentDidMount() {
        var REACT_APP_API_URL = 'https://fs0n0ea1pf.execute-api.us-east-1.amazonaws.com/dev'
        var url = REACT_APP_API_URL + '/tweets/?relevant=True&limit=300'
        fetch(url)
            .then(res => res.json())
            .then((data) => {
                this.setState({ contacts: data })
            })
            .catch(console.log)
        var mapUrl = REACT_APP_API_URL + '/map/'
        fetch(mapUrl)
            .then(res => res.json())
            .then((data) => {
                this.setState({ mapPoints: data })
            })
            .catch(console.log)
        var url2 = REACT_APP_API_URL + '/count/byDay/'
        fetch(url2)
            .then(res => res.json())
            .then((data) => {
                this.setState({ dates: data })
            })
            .catch(console.log)
    }

}

export default MapPage
