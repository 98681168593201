import React from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";


const data = [
    {
        name: "Closed",
        odds: 290.7
    },
    {
        name: "Lanes",
        odds: 231.1
    },
    {
        name: "Shutdown",
        odds: 201.5
    },
    {
        name: "Bear",
        odds: 182.8
    },
    {
        name: "Working",
        odds: 114.2
    },
    {
        name: "Site",
        odds: 91.2
    },
    {
        name: "Shut",
        odds: 74.3
    }
];


export default function MyBarChart() {
    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={data} layout="vertical" barCategoryGap={10} margin={{ top: 0, right: 40, left: 0, bottom: 25 }}>
                <Bar dataKey="odds" fill="#3f51b5" label={{ position: 'right' }} />
                <XAxis type="number" hide />
                <YAxis type="category" width={80} dataKey="name" />
            </BarChart>
        </ResponsiveContainer>

    );
}
