import React, { Component } from 'react';
import "./App.css"
import MapPage from './components/mapPage';
import DenseAppBar from './components/appbar'
import LandingPage from './components/landingPage';
import AboutPage from './components/aboutPage';
import AnalyticsPage from './components/analyticsPage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';




class App extends Component {
    render() {
        return (
            <Router>
                <div className='App'>
                    <div className="footerBackground "></div>
                    <div className="headerBackground "></div>
                    <DenseAppBar />
                    <Switch>
                        <Route exact path="/">
                            <LandingPage></LandingPage>
                        </Route>
                        <Route exact path="/map">
                            <MapPage />
                        </Route>
                        <Route exact path="/about">
                            <AboutPage />
                        </Route>
                        <Route exact path="/analytics">
                            <AnalyticsPage />
                        </Route>
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App;