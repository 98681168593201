import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { ThemeProvider } from '@material-ui/styles';
// import { createTheme } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';


ReactDOM.render(
        <App />,
    document.getElementById('root')
  );