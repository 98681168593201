import React, { Component } from 'react';
import TimeSeriesChart from './lineGraph';
import { Grid } from '@material-ui/core';
// import CalendarHeatmap from 'react-calendar-heatmap';
// import 'react-calendar-heatmap/dist/styles.css';
// import ReactTooltip from 'react-tooltip';
import './react-calendar-heatmap.css'
import MyBarChart from './barChart';
// import StatsCard from './statsCard';
import TopRetweets from './topRetweets';

// const today = new Date();

class AnalyticsPage extends Component {

    render() {
        // var options = { year: '2-digit', month: 'numeric', day: 'numeric' }
        // var dataIsLoaded = (this.state.dates.length !== 0)
        
        return (
            <div>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '50vh' }}>
                    <Grid item xs={12} md={4} container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '50vh' }}>
                        <div style={{ width: '100%' }}>
                            <TimeSeriesChart data={this.state.countsByDay}></TimeSeriesChart>
                        </div>
                        <div style={{ width: '100%' }} >
                            <MyBarChart></MyBarChart>
                        </div>
                        {/* <div style={{ width: '100%' }}>
                            {dataIsLoaded ?
                                <div>
                                    <CalendarHeatmap
                                        startDate={shiftDate(today, -365)}
                                        endDate={today}
                                        // startDate={new Date(this.state.dates[0].date)}
                                        // endDate={new Date(this.state.dates.at(-1).date)}
                                        values={this.state.dates}
                                        classForValue={value => {
                                            if (!value) {
                                                return 'color-empty';
                                            }
                                            else if (value.count < 5) {
                                                return `color-github-1`;
                                            }
                                            else if (value.count < 15) {
                                                return `color-github-2`;
                                            }
                                            else if (value.count < 25) {
                                                return `color-github-3`;
                                            }
                                            else if (value.count >= 25) {
                                                return `color-github-4`;
                                            }
                                        }}
                                        tooltipDataAttrs={value => {
                                            return {
                                                'data-tip': `${new Date(value.date).toLocaleDateString("en-US", options)} : ${value.count
                                                    } tweets`,
                                            };
                                        }}
                                    /><ReactTooltip /></div> : <div />}
                        </div> */}
                    </Grid>
                    <Grid item xs={12} md={4} container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '50vh' }}>
                        {/* <StatsCard/> */}
                        
                        <div>
                            <TopRetweets></TopRetweets>
                        </div>
                        {/* <div>
                            <h1>hello</h1>
                        </div> */}

                    </Grid>
                </Grid>
            </div>
        )
    }
    state = {
        countsByDay: [],
        dates: []
    };

    componentDidMount() {
        var url = process.env.REACT_APP_API_URL + '/api/count/byDay/'
        fetch(url)
            .then(res => res.json())
            .then((data) => {
                this.setState({ countsByDay: data })
            })
            .catch(console.log)
        var url2 = process.env.REACT_APP_API_URL + '/api/count/byDay'
        fetch(url2)
            .then(res => res.json())
            .then((data) => {
                this.setState({ dates: data })
            })
            .catch(console.log)
    }

}

export default AnalyticsPage

// function shiftDate(date, numDays) {
//     const newDate = new Date(date);
//     newDate.setDate(newDate.getDate() + numDays);
//     return newDate;
// }