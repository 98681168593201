import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import { ListItemIcon } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import InfoIcon from '@material-ui/icons/Info';
import GitHubIcon from '@material-ui/icons/GitHub';
import HomeIcon from '@material-ui/icons/Home';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
import { Drawer, List } from '@material-ui/core';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};
const drawerWidth = 800;

class DenseAppBar extends React.Component {
  state = {

    anchorEl: null,
  };


  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    

    return (
      <div className={classes.root}>
        <AppBar position="static" elevation={0} className={classes.customizeToolbar} style={{marginBottom:'10px',background:'#002984'}} >
          <Toolbar variant="dense">
            <IconButton className={classes.menuButton} aria-label="Menu" aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex} component={Link} to={'/'} style={{ textDecoration: 'none', color: '#ffffff' }}>
              Landslide Detection
            </Typography>

            <div>
              <Drawer
                id="menu-appbar"
                anchorel={anchorEl}
                open={open}
                onClose={this.handleClose}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
              >
                <List>
                <ListItem button key={'Home'} component={Link} to={'/'} onClick={this.handleClose}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Home'} />
                  </ListItem>
                  <Divider />
                  <ListItem button key={'Map'} component={Link} to={'/map'} onClick={this.handleClose}>
                    <ListItemIcon>
                      <MapIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Map'} />
                  </ListItem>
                  <Divider />
                  {/* <ListItem button key={'Analytics'} component={Link} to={'/analytics'} onClick={this.handleClose}>
                    <ListItemIcon>
                      <ShowChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Analytics'} />
                  </ListItem>
                  <Divider /> */}
                  <ListItem button key={'About'} component={Link} to={'/about'} onClick={this.handleClose}>
                    <ListItemIcon>
                      <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary={'About'} />
                  </ListItem>
                  <Divider />
                  <ListItem button key={'Github'} component={'a'} href={'https://github.com/tbusath8/landslide-detection-aws'} target={"_blank"} rel={"noreferrer"} onClick={this.handleClose}>
                    <ListItemIcon>
                      <GitHubIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Github'} />
                  </ListItem>
                </List>
              </Drawer>
            </div>

          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

DenseAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DenseAppBar);